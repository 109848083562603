import './SliderA.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import { SwiperSlide } from 'swiper/react';

import { SliderAContentSection } from '../contentTypes';
import { Slider } from './Slider';

export interface SliderAProps extends SliderAContentSection {}

export const SliderA = ({ fullHeight, sliderList }: SliderAProps) => {
  return (
    <div className="SliderA">
      <b2x.EqualHeight>
        <Slider
          className={classnames({ 'slider-full-height': fullHeight })}
          navigation
          pagination
          slidesPerView={1}
          spaceBetween={0}
          watchSlidesProgress
        >
          {sliderList?.map((slide, index) => (
            <SwiperSlide key={slide.contentSectionId}>
              {(slideData) => (
                <div className={'slide'}>
                  <b2x.EqualHeightElement name="slide">
                    <div className="background position-relative h-100">
                      {slide.video?.src && slide.video.src.length > 1 ? (
                        <b2x.DeprecatedVideoAsBackgroundFromContent {...slide.video} />
                      ) : (
                        <b2x.ImageFromContent {...slide.img} fluid />
                      )}
                    </div>
                  </b2x.EqualHeightElement>
                  <div
                    className={classnames(
                      'message d-md-flex h-100 align-items-center',
                      // padding
                      'p-3 p-lg-5',
                      // alignment
                      { 'text-start': slide.copy?.alignment === 'left' },
                      { 'text-center': slide.copy?.alignment === 'center' },
                      { 'text-end': slide.copy?.alignment === 'right' }
                    )}
                  >
                    <div className="container-xxl">
                      <div className="col-lg-4 col-md-5 ps-md-5 ps-lg-3 ps-xl-0">
                        <div className={classnames(`text-${slide.copy?.color}`)}>
                          {slide.copy?.preTitle && (
                            <h3 className="display-3 text-primary pre-title">{b2x.formatHtml(slide.copy.preTitle)}</h3>
                          )}
                          {slide.copy?.title && <h3 className="h1 title">{b2x.formatHtml(slide.copy.title)}</h3>}
                          {slide.copy?.content && (
                            <div className="description h4 fw-normal mb-3">{b2x.formatHtml(slide.copy.content)}</div>
                          )}
                        </div>
                        {slide.cta && slide.cta.label && (
                          <div>
                            <b2x.DeprecatedCta
                              className={classnames('btn', `btn-${slide.cta.variant}`, 'd-none d-md-inline-block ')}
                              cta={slide.cta}
                            >
                              {b2x.formatHtml(slide.cta.label)}
                            </b2x.DeprecatedCta>
                            <b2x.DeprecatedCta className={classnames('btn btn-primary d-md-none')} cta={slide.cta}>
                              {b2x.formatHtml(slide.cta.label)}
                            </b2x.DeprecatedCta>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </SwiperSlide>
          ))}
        </Slider>
      </b2x.EqualHeight>
    </div>
  );
};
