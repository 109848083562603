import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { ImageContentBoxSection } from './contentTypes';

export interface ImageContentBoxProps extends ImageContentBoxSection {
  imageWithNegativeTop?: boolean;
}

export const ImageContentBox = ({ copy, imageWithNegativeTop, img }: ImageContentBoxProps) => {
  return (
    <div className="row mb-5 mb-lg-1">
      <div
        className={classnames(
          'col-lg-6 p-0',
          copy?.textAlignment === 'start' ? 'order-lg-1' : '',
          imageWithNegativeTop ? 'mt-n5' : ''
        )}
      >
        <b2x.ImageFromContent {...img} fluid />
      </div>
      <div className="col-lg-6 d-lg-flex align-items-center px-lg-5">
        <div className="px-lg-5">
          <div className="display-2 mt-2 mt-lg-0">{b2x.formatHtml(copy?.title)}</div>
          {copy?.subTitle && <div className="h4 fw-normal my-3 text-uppercase">{b2x.formatHtml(copy.subTitle)}</div>}
          {copy?.content && <div className="h4 fw-normal my-3">{b2x.formatHtml(copy.content)}</div>}
        </div>
      </div>
    </div>
  );
};
