import { b2x } from '@b2x/react/src';

import { NewsletterContentType } from '../contentTypes';
import { NewsletterForm } from '../forms/NewsletterForm';
import { Page } from './Page';

export interface NewsletterSubscribePageProps {}

export const NewsletterSubscribePage = () => {
  const content = b2x.useContent<NewsletterContentType>('NEWSLETTER_PAGE_CONTENT');

  return (
    <Page withGenericBackgroundImage>
      <div className="container-xxl">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="row">
              <div className="col-lg-6 px-lg-0 d-none d-md-block">
                <b2x.ImageFromContent {...content?.body.img} fluid />
              </div>
              <div className="col-lg-6 px-lg-0 ">
                <div className="bg-white h-100 px-3 py-3 py-lg-0 d-lg-flex align-items-center">
                  <div>
                    <h1 className="display-2 text-center">{b2x.formatHtml(content?.body.title)}</h1>
                    <div className="my-3 text-center">{b2x.formatHtml(content?.body.content)}</div>
                    <div className="row justify-content-center">
                      <div className="col-lg-10">
                        <NewsletterForm emailFieldStyle="textInput" source="website-modal" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};
