import './OurManufacturing.scss';

import { b2x } from '@b2x/react/src';
import classNames from 'classnames';

import { OurManufacturingSection } from './contentTypes';

export interface OurManufacturingProps extends OurManufacturingSection {}

export const OurManufacturing = ({ img, itemList, title }: OurManufacturingProps) => {
  return (
    <div className="OurManufacturing my-3 my-lg-5">
      {title && (
        <div className="container-xxl ">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="display-1 mb-3 mb-lg-5">{title}</h1>
            </div>
          </div>

          <div className="position-relative tomato-box my-3">
            <div className="position-absolute tomato-box-img d-none d-xl-block">
              <b2x.ImageFromContent {...img} />
            </div>
            <div className="row">
              <b2x.EqualHeight>
                {itemList &&
                  itemList.map((item, index) => {
                    return (
                      <div className="col-lg-6 mb-3" key={item.contentSectionId}>
                        <b2x.EqualHeightElement disable={['xs', 'sm']} name="manufacturing">
                          <div
                            className={classNames(
                              `bg-${item.backgroundColor}`,
                              `text-${item.textColor}`,
                              'rounded-3 p-3 h-100'
                            )}
                          >
                            <div className="row">
                              {index % 2 === 1 && <div className="col-xl-4 d-none d-lg-flex"></div>}
                              <div className="col-xl-8">
                                <div className="h3">{b2x.formatHtml(item.title)}</div>
                                <div className="h4 fw-normal">{b2x.formatHtml(item.content)}</div>
                              </div>
                            </div>
                          </div>
                        </b2x.EqualHeightElement>
                      </div>
                    );
                  })}
              </b2x.EqualHeight>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
