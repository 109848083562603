import './NewsletterForm.scss';

import { b2x } from '@b2x/react/src';

import { Button } from '../Button';
import { t } from '../i18n/i18n';

// eslint-disable-next-line @typescript-eslint/ban-types
type AdditionalPropertiesFormValues = {};

// eslint-disable-next-line @typescript-eslint/ban-types
type AdditionalPropertiesValidationSchema = b2x.yup.ObjectSchema<{}>;

export type NewsletterFormProps = Omit<
  b2x.NewsletterFormProps<AdditionalPropertiesFormValues, AdditionalPropertiesValidationSchema>,
  | 'additionalPropertiesInitialValues'
  | 'additionalProperties'
  | 'additionalPropertiesValidationSchema'
  | 'validationSchemaSelector'
>;

export const NewsletterForm = ({ emailFieldStyle = 'textInput', ...otherProps }: NewsletterFormProps) => {
  return (
    <b2x.NewsletterFormHelper<AdditionalPropertiesFormValues, AdditionalPropertiesValidationSchema>
      {...otherProps}
      emailFieldStyle={emailFieldStyle}
      redirectToHomeOnSuccessModalClose
      validationSchemaSelector={{ name: false, surname: false }}
    >
      {({ fieldsHelper }) => (
        <>
          {emailFieldStyle === 'inputGroup' && (
            <b2x.FormGroup {...fieldsHelper.email.formGroup} label={undefined}>
              <b2x.InputGroup {...fieldsHelper.email.inputGroup}>
                <b2x.TextInput
                  {...fieldsHelper.email.textInput}
                  className="me-2"
                  placeholder={t('form.newsletterForm.email.placeholder')}
                />
                <Button
                  {...fieldsHelper.buttons.submit}
                  label={t('form.newsletterForm.buttons.submit.label')}
                  variant="outline-primary"
                />
              </b2x.InputGroup>
            </b2x.FormGroup>
          )}
          {emailFieldStyle === 'textInput' && (
            <b2x.FormGroup {...fieldsHelper.email.formGroup} label={undefined}>
              <b2x.TextInput {...fieldsHelper.email.textInput} />
            </b2x.FormGroup>
          )}
          <b2x.FormGroup {...fieldsHelper.newsletterConsent.formGroup} label={undefined}>
            <b2x.Checkbox {...fieldsHelper.newsletterConsent.checkbox} labelClassName="extra-small text-start" />
          </b2x.FormGroup>
          {emailFieldStyle === 'textInput' && (
            <div className="d-block text-center">
              <b2x.Button label={t('form.newsletterForm.buttons.submit.label')} {...fieldsHelper.buttons.submit} />
            </div>
          )}
        </>
      )}
    </b2x.NewsletterFormHelper>
  );
};
