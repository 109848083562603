import { Resource } from '../i18n';

export const it: Resource = {
  form: {
    newsletterForm: {
      buttons: { submitButton: { label: 'Iscriviti' } },
    },
    personalDataForm: {
      phoneNumbers: { label: 'Telefono' },
    },
    productForm: {
      size: 'Formato',
    },
  },
  misc: {
    pricePerUnit: '({{price}} al {{measurementUnit}})',
    storeName: 'Fiordelisi',
    youSearchedFor: 'Hai cercato',
  },
  recipe: {
    cooking: 'Cottura',
    difficulty: 'Difficoltà',
    ingredients: 'Ingredienti',
    procedure: 'Procedimento',
    timing: 'Preparazione',
  },
  welcome: 'Benvenuto su store-fiordelisi!',
};
